import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import FilterTest from './../components/forms/Filter/test'

const SecondPage = () => (
  <Layout>
    <SEO title="Page two" />

    <div className="container">
      <h1>Hi from the second page</h1>
      <p>Welcome to page 2</p>
      <Link to="/">Go back to the homepage</Link>

      {/* <FilterTest /> */}
    </div>

  </Layout>
)

export default SecondPage
